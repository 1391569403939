/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState, useRef } from "react"
import {
  postInventorySubmissions,
  patchInventorySubmission,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"
import NotificationContext from "../../../context/Notification/NotificationContext"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import TableFilter from "../../../components/Table/Inventory/Submissions/TableFilter"
import Button from "../../../components/Elements/Button"
import Skeleton from "../../../components/Skeleton"
import {
  green,
  greenLight,
  yellow,
  yellowLight,
  red,
  redLight,
  blue,
  blueLight,
} from "../../../components/Styles"
import Modal from "../../../components/Modal/Modal"
import CancelConfirm from "../../../components/Forms/CancelConfirm"
import Pagination from "../../../components/Pagination"
import useIsMount from "../../../utils/useIsMount"
const Status = props => {
  const { status } = props

  let bg = "#222",
    color = "#fff"

  if (status === "submitted") {
    bg = redLight
    color = red
  }
  if (status === "processing") {
    bg = yellowLight
    color = yellow
  }
  if (status === "cancelled") {
    bg = "#eee"
    color = "rgba(0, 0, 0, 0.2)"
  }
  if (status === "receiving") {
    bg = blueLight
    color = blue
  }
  if (status === "complete") {
    bg = green
    color = greenLight
  }

  return (
    <div
      css={css`
        text-transform: capitalize;
        border-radius: 50px;
        padding: 4px 20px;
        text-align: center;
        font-size: 14px;
        width: 100%;
        color: ${color};
        background: ${bg};
      `}
    >
      {status}
    </div>
  )
}
function Submissions(props) {
  const { state } = useContext(StateContext)
  const { updateActiveInventorySubmission, inventorySubmissionOpen } = props

  useEffect(async () => {
    if (inventorySubmissionOpen === false) {
      setLoading(true)
      const x = await getSubmissions()
      if (x) {
        setList(x.results)
        setTotal(x.total)
        let pages = Math.ceil(x.total / perPage)
        setTotalPage(pages)
      }
      setLoading(false)
    }
  }, [inventorySubmissionOpen])

  const { toggleNotification } = useContext(NotificationContext)
  const { token } = state

  const isMount = useIsMount()
  const [current, setCurrent] = useState(null)
  const [open, setOpen] = useState(false)

  const [filters, setFilters] = useState({ page: 1 })
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [perPage, setPerPage] = useState(5)

  const getSubmissions = async () => {
    // if (loading === false) setLoading(true)
    try {
      const res = await postInventorySubmissions(token, filters)
      if (res.ok) {
        const json = await res.json()
        // setLoading(false)
        return json
      } else
        toggleNotification({
          content: "An unexpected error occurred.",
          error: true,
        })
      // setLoading(false)
      return false
    } catch (e) {
      toggleNotification({
        content: "An unexpected error occurred.",
        error: true,
      })
      // setLoading(false)
      return false
    }
  }

  const onChangeSubmissionFilters = filters => {
    setFilters(prevFilters => ({ ...prevFilters, ...filters, page: 1 }))
  }

  const onPageChange = v => {
    setFilters(prevFilters => ({
      ...prevFilters,
      page: v,
    }))
  }

  useEffect(async () => {
    if (!isMount) {
      const x = await getSubmissions()
      if (x) {
        setList(x.results)
        setTotal(x.total)
        let pages = Math.ceil(x.total / perPage)
        setTotalPage(pages)
      }
      setLoading(false)
    }
  }, [filters])

  // useEffect(() => {
  //   let pages = Math.ceil(total / perPage)
  //   setTotalPage(pages)
  // }, [total])

  useEffect(async () => {
    if (list.length === 0) {
      const x = await getSubmissions()
      if (x !== null) {
        setList(x.results)
        setTotal(x.total)
        let pages = Math.ceil(x.total / perPage)
        setTotalPage(pages)
      }

      setLoading(false)
    }
  }, [])

  const scrollRef = useRef()

  return (
    <div ref={scrollRef}>
      <TableFilter onChange={onChangeSubmissionFilters} list={list} />
      {loading === true ? (
        <Skeleton table />
      ) : (
        <>
          <Table
            columns={[
              "ID",
              "SKUs",
              "Total Inventory",
              "Total Containers",
              "Status",
              "Date Submitted",
              "Actions",
            ]}
          >
            {list.length > 0 ? (
              <>
                {list.map((item, k) => {
                  const {
                    application_id,
                    assets,
                    changed,
                    company,
                    created,
                    email,
                    first_name,
                    last_name,
                    id,
                    notes,
                    phone,
                    status,
                  } = item

                  let totalInventory = 0
                  assets.map(
                    i => (totalInventory = totalInventory + i.product_quantity)
                  )
                  let totalContainers = 0
                  assets.map(
                    i =>
                      (totalContainers = totalContainers + i.shipment_quantity)
                  )

// console.log(new Date(parseInt(created)), 'creet')
                    const createdDate = created
                  return (
                    <Tr>
                      <Td>{id}</Td>
                      <Td>{assets.map(i => i.asset.sku).join(",")}</Td>
                      <Td>{totalInventory}</Td>
                      <Td>{totalContainers}</Td>
                      <Td>
                        <Status status={status} />
                      </Td>
                      <Td>{createdDate}</Td>
                      <Td>
                        <div className="flex space-x-2 justify-end">
                          {status === "draft" || status === "submitted" ? (
                            <Button
                              pink
                              onClick={() => {
                                updateActiveInventorySubmission({
                                  isNew: false,
                                  data: item,
                                })
                              }}
                            >
                              Edit
                            </Button>
                          ) : (
                            <Button
                              red
                              onClick={() => {
                                updateActiveInventorySubmission({
                                  isNew: false,
                                  data: item,
                                })
                              }}
                            >
                              View
                            </Button>
                          )}
                          {(status === "submitted" ||
                            status === "processing") && (
                              <Button
                                onClick={() => {
                                  // TODO cancel here...
                                  setCurrent(item)
                                  setOpen(true)
                                }}
                              >
                                Cancel
                              </Button>
                            )}
                        </div>
                      </Td>
                    </Tr>
                  )
                })}
              </>
            ) : (
              <Tr>
                <Td>You have no previous Inventory Submisisons.</Td>
              </Tr>
            )}
          </Table>
          <Pagination
            notLink
            totalPages={totalPage}
            page={filters.page}
            scrollRef={scrollRef}
            setPage={onPageChange}
          />
        </>
      )}

      <Modal
        isOpen={open}
        closeModal={() => setOpen(false)}
        title={"Cancel Inventory Submisisons"}
      >
        <CancelConfirm
          onYes={async () => {

            console.log(current, "current")

            const newObj = { ...current, status: "canceled" }

            try {
              const res = await patchInventorySubmission(
                token,
                newObj.id,
                newObj
              )

              if (res.ok) {
                const json = await res.json()
                console.log(json)

                const newList = [...list]
                newList.map((item, k) => {
                  if (item.id === newObj.id) newList[k] = newObj
                })
                setList(newList)
                toggleNotification({ content: "Canceled successfully" })
                setOpen(false)
                return json
              } else
                toggleNotification({
                  error: true,
                  content: "An unexpected error occurred",
                })
            } catch (e) {
              toggleNotification({
                error: true,
                content: "An unexpected error occurred",
              })
            }

            //put the post request here
            // let res = await postFunction( )
            // if (res.status === 200) {
            //   res = await res.json()
            //   toggleNotification({ content: res.message })
            //   const data = await Listing(state.token, company.value)
            //   setList(data)
            // } else {
            //   toggleNotification({
            //     content: "Sorry, there was an error.",
            //     error: true,
            //   })
            // }
            setOpen(false)
            setCurrent(null)
          }}
          onNo={() => {
            setOpen(false)
            setCurrent(null)
          }}
        />
      </Modal>
    </div>
  )
}

export default Submissions
