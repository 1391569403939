/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import InventoryList from "../../templates/Dashboard/Inventory/InventoryList"
import Submissions from "../../templates/Dashboard/Inventory/Submissions"
// import { postInventorySubmissions } from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
// import NotificationContext from "../../context/Notification/NotificationContext"
import { Tab } from "@headlessui/react"
import { classNames } from "../../utils"
import InventorySubmission from "../../components/InventorySubmission"
import AccessDenied from "../../components/AccessDenied"
import InventoryProvider from "../../context/Inventory/InventoryProvider"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
const Tabs = props => {
  const tabs = [
    // {
    //   name: "Inventory",
    //   content: <InventoryList location={props.location} {...props} />,
    // },
    {
      name: "Submissions",
      content: <Submissions location={props.location} {...props} />,
    },
  ]
  return (
    <div>
      <Tab.Group defaultIndex={0}>
        <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
          {tabs.map((item, k) => (
            <Tab
              key={k}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "border-[#222] text-[#222]"
                    : "border-transparent text-[#222] hover:text-gray-500",
                  "whitespace-nowrap pb-[28px] px-1 border-b-2 text-med-16"
                )
              }
            >
              {item.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="pt-[30px]">
          {tabs.map((item, k) => {
            return <Tab.Panel key={k}>{item.content}</Tab.Panel>
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
function Inventory({ location }) {
  // const { toggleNotification } = useContext(NotificationContext)
  const { state } = useContext(StateContext)
  // const { token } = state
  const { userType } = useContext(AuthenticationContext)
  const [inventorySubmissionOpen, setInventorySubmissionOpen] = useState(false)
  const [inventorySubmissionNew, setInventorySubmissionNew] = useState(false)
  const [inventorySubmissionExisting, setInventorySubmissionExisting] =
    useState(false)

  const [inventoryItemFormValues, setInventoryItemFormValues] = useState({})
  // const [total, setTotal] = useState(0)
  // const [page, setPage] = useState(1)
  // const [totalPage, setTotalPage] = useState(1)
  // const [perPage, setPerPage] = useState(5)

  const closeModal = async (updatedOrSavedEntity = null) => {
    setInventorySubmissionOpen(false)
    if (updatedOrSavedEntity !== null) {
    }
  }
  // const [filters, setFilters] = useState({})
  // const [list, setList] = useState([])
  // const [loading, setLoading] = useState(true)

  // useEffect(async () => {
  //   if (list.length === 0) {
  //     const x = await getSubmissions()
  //     if (x !== null) {
  //       setList(x.results)
  //       setTotal(x.total)
  //     }
  //     setLoading(false)
  //   }
  // }, [])

  // const getSubmissions = async () => {
  //   try {
  //     const res = await postInventorySubmissions(token, page, filters)
  //     if (res.ok) {
  //       const json = await res.json()
  //       return json
  //     } else
  //       toggleNotification({
  //         content: "An unexpected error occurred.",
  //         error: true,
  //       })
  //     return false
  //   } catch (e) {
  //     toggleNotification({
  //       content: "An unexpected error occurred.",
  //       error: true,
  //     })
  //     return false
  //   }
  // }

  // const onChangeSubmissionFilters = filters => {
  //   setFilters(filters)
  // }

  // useEffect(async () => {
  //   const x = await getSubmissions()
  //   if (x) {
  //     setList(x.results)
  //     setTotal(x.total)
  //   }
  // }, [filters])

  const updateActiveInventorySubmission = ({
    isNew: isNew = true,
    data: data = {},
  }) => {
    console.log(isNew, "isNew")
    setInventorySubmissionOpen(true)
    setInventoryItemFormValues(data)
    setInventorySubmissionNew(isNew)
  }

  // const onPageChange = v => {
  //   setPage(v)
  // }

  // useEffect(async () => {
  //   const x = await getSubmissions()
  //   if (x) {
  //     setList(x.results)
  //     setTotal(x.total)
  //   }
  // }, [page])

  // useEffect(() => {
  //   let pages = Math.ceil(total / perPage)

  //   setTotalPage(pages)
  // }, [total])

  if (!userType.brandManager) return <AccessDenied />

  return (
    <LayoutDashborad
      heading="Inventory Submissions"
      subheading="View & manage your previous inventory submissions."
      trail={[...dashboardTrail, { text: "Manage Inventory", link: "#" }]}
      report
    >
      {/* <InventoryProvider> */}
      <Submissions inventorySubmissionOpen={inventorySubmissionOpen}
          setInventorySubmissionOpen={setInventorySubmissionOpen}
          inventorySubmissionNew={inventorySubmissionNew}
          setInventorySubmissionNew={setInventorySubmissionNew}
          inventorySubmissionExisting={inventorySubmissionExisting}
          setInventorySubmissionExisting={setInventorySubmissionExisting}
          inventoryItemFormValues={inventoryItemFormValues}
          updateActiveInventorySubmission={updateActiveInventorySubmission}
          location={location} />
        {/* <Tabs
          inventorySubmissionOpen={inventorySubmissionOpen}
          setInventorySubmissionOpen={setInventorySubmissionOpen}
          inventorySubmissionNew={inventorySubmissionNew}
          setInventorySubmissionNew={setInventorySubmissionNew}
          inventorySubmissionExisting={inventorySubmissionExisting}
          setInventorySubmissionExisting={setInventorySubmissionExisting}
          inventoryItemFormValues={inventoryItemFormValues}
          updateActiveInventorySubmission={updateActiveInventorySubmission}
          location={location}
          // list={list}
          // onChangeSubmissionFilters={onChangeSubmissionFilters}
          // page={page}
          // totalPage={totalPage}
          // onPageChange={onPageChange}
        /> */}
        <InventorySubmission
           enterFormValues={inventoryItemFormValues}
           closeModal={closeModal}
           inventorySubmissionNew={inventorySubmissionNew}
           inventorySubmissionOpen={inventorySubmissionOpen}
         />
      {/* </InventoryProvider> */}
    </LayoutDashborad>
  )
}
export default Inventory
