/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

function QuantitySelect(props) {
  const { small, decrement, increment, count, label } = props

  return (
    <div className="flex ">
      <div
        className={`custom-number-input ${small ? `h-[32px] w-[62px] px-[4px]` : `h-[50px] w-[30%]`
          }  flex bg-white flex-row items-center overflow-hidden lg:mr-4`}
        css={css`
          background: #ffffff;
          border: 1px solid #ebebeb;
          box-sizing: border-box;
          border-radius: 8px;
          &:hover {
            border-color: #222;
          }
          min-width: ${small ? `62px` : `none`};
        `}
      >
        <button
          data-action="decrement"
          onClick={decrement}
          className={`h-full ${small ? `w-[20px] relative -top-[1px]` : `w-18`
            } cursor-pointer outline-none bg-white overflow-hidden ${small && `text-reg-12`
            }`}
          css={css`
            border-radius: 8px 0 0 8px;
          `}
        >
          <span className="">−</span>
        </button>
        <input
          type="text"
          className={` ${small ? `text-reg-12` : `text-med-14`
            } outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden p-0 z-[1] relative`}
          name="custom-input-number"
          value={count}
        />
        <button
          data-action="increment"
          onClick={increment}
          className={`h-full ${small ? `w-[20px] relative -top-[1px]` : `w-18`
            } cursor-pointer bg-white overflow-hidden ${small && `text-reg-12`}`}
          css={css`
            border-radius: 0 8px 8px 0;
          `}
        >
          <span className="">+</span>
        </button>
      </div>
      {label && <label
        // for={inputName}
        className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
        css={css`
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.02em;
          color: #717171;
        `}
      >
        {label}
      </label>}
    </div>
  )
}

export default QuantitySelect
