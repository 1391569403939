/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useRef, useState } from "react"
import FloatingInput from "../../../Elements/Form/FloatingInput"
import SearchIcon from "../../../../assets/icons/search.svg"
import { useFieldArray, useForm } from "react-hook-form"
import FilterSelect from "../../../Elements/FilterSelect"
import Tag from "../../../Elements/Tag"
import useIsMount from '../../../../utils/useIsMount'
function TableFilter({
  name,
  onChange,
  list,
  bundle,
  loadRef,
  setShowPagination,
  setHasMore: setLoader,
}) {
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef()

  // const { control, setValue, register, getValues } = useForm()
  let initData = {
    query: "",
    status: "all",
    // fulfillment_type: "all",
    // stock: "all",
    // allocation: "all",
  }
  const [data, setData] = useState(initData)
  const isMount = useIsMount()

  const onChangeForm = async (type, v) => {
    setData(prevState => ({ ...prevState, [type]: v }))
  }
  useEffect(() => {
    if(!isMount) onChange(data)
  }, [data])
  return (
    <div
      className={` ${false && `sticky`} top-[-1px] z-[1] ${
        false && isSticky === true
          ? "md:pt-[90px] transition-all"
          : "transition-none"
      }`}
      ref={ref}
    >
      <div
        className={`border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white ${
          false && isSticky === true && `!rounded-none`
        }`}
      >
        <div className="flex flex-col md:flex-row md:items-center md:justify-end space-y-[15px] md:space-y-0 md:space-x-[15px] w-full">
          <div className="md:grow md:!mr-auto">
            <FloatingInput
              label="Search by Brand, SKU or Title"
              icon={<SearchIcon />}
              onChange={v => onChangeForm("query", v ? v.target.value : null)}
              className="!mb-0"
              name="query"
            />
          </div>
          <div className="text-center md:text-left">
            <FilterSelect
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "submitted",
                  label: "Submitted",
                },
                {
                  value: "processing",
                  label: "Processing",
                },
                {
                  value: "receiving",
                  label: "Receiving",
                },
                {
                  value: "complete",
                  label: "Complete",
                },
                {
                  value: "canceled",
                  label: "Canceled",
                },
                {
                  value: "draft",
                  label: "Draft",
                },
              ]}
              label="Status"
              onChange={v => onChangeForm("status", v ? v : null)}
              className={"!border-0"}
              fromRight
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableFilter
