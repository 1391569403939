import React from "react"
import Button from "../Elements/Button"

function CancelConfirm({ onYes, onNo }) {
  return (
    <div>
      <p className="mb-6">
        Are you sure you want to Cancel this? This action <b>cannot</b> be
        undone.
      </p>
      <div className="flex justify-between">
        <Button red onClick={onYes}>
          Yes
        </Button>
        <Button outline onClick={onNo}>
          No
        </Button>
      </div>
    </div>
  )
}

export default CancelConfirm
